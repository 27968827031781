/**
 * Created by osirvent on 16/05/2017.
 */
angular
    .module('annexaApp')
    .component('annexaSessionPoints', {
        templateUrl: './components/sec/annexa-session-points/annexa-session-points.html',
        controller: ['SecFactory', 'RestService', 'Language', '$rootScope', 'AdminFactory', '$filter', function(SecFactory, RestService, Language, $rootScope, AdminFactory, $filter) {
            var vm = this;

            vm.proposals = [];
            vm.pointTitle = "";
            vm.pointObservations = "";
            vm.documents = [];
			vm.delegationTypes = [];
			vm.selectAllModel = false;
            vm.languageColumn = Language.getActiveColumn();
            vm.showTable = true;
			vm.isActiveCouncillor = false;
            var fixProposals = function (items) {
                vm.proposals = [];
                vm.pointTitle = "";
                vm.pointObservations = "";
                vm.showTable = true;
				vm.selectAllModel = false;
				vm.proposalsContainer = false;
				vm.containerOrder = undefined;
				vm.delegationType = undefined;
                _.forEach(items, function (value) {
                	var restrictedAccessEMGDE = false;
                	if(value.createdTransaction.dossier.restrictedAccessEMGDE !== 'undefined') {
                		restrictedAccessEMGDE = value.createdTransaction.dossier.restrictedAccessEMGDE;
            		}
                    var prop = {
                        selected: false,
                        createdUser: {
                            name: value.senderUser.name,
                            surename1: value.senderUser.surename1,
                            surename2: value.senderUser.surename2
                        },
                        proposalNumber: value.proposalNumber,
                        createdDate: value.sendDate,
                        extract: value.extract,
                        id: value.id,
                        documents:[],
                        documentsProp: value.documents,
                        votationType:'ORDINARY',
                        requiredVoteState:'APPROVED_SIMPLE_MAJORITY',
                        createdTransaction: {
                        	id:value.createdTransaction.id,
                        	dossier:{
                        		id:value.createdTransaction.dossier.id,
                        		subject:value.createdTransaction.dossier.subject,
                        		dossierNumber:value.createdTransaction.dossier.dossierNumber,
                        		dossierStatus:value.createdTransaction.dossier.dossierStatus,
                        		restrictedAccessEMGDE:restrictedAccessEMGDE
                        	}
                        },
                        profile: value.profile
                    };

                    vm.proposals.push(prop);
                });
            }

            var fixTitleAndObservations = function () {
                vm.proposals = [];
                vm.documents = [];
                vm.pointTitle = "";
                vm.pointObservations = "";
                vm.votationType = "ORDINARY";
                vm.requiredVoteState = "APPROVED_SIMPLE_MAJORITY";
                vm.showTable = false;
				vm.selectAllModel = false;
				vm.proposalsContainer = false;
				vm.containerOrder = undefined;
				vm.delegationType = undefined;
            }

			vm.selectAllFunction = function(){
				if(vm.proposals && vm.proposals.length > 0){
					_.forEach(vm.proposals, function(proposal){
						if(vm.selectAllModel){
							proposal.selected = true;
						}else{
							proposal.selected = false;
						}
					});					
				}
			}
			
            this.$onChanges = function (changes) {
                if(changes.type) {
                    vm.proposals = [];

                    var pointType = changes.type.currentValue;
                    
                    if(_.contains(['VOTE'], pointType.type)){
                    	 vm.showVotes = true;
                    }else if(_.contains(['PROPOSAL', 'OPINION'], pointType.type) && pointType.vote){
                    	vm.showVotes = true;
                    }else{
                    	 vm.showVotes = false;
                    }
                    
                    
                    if(!_.contains(['VOTE', 'NOVOTE', 'GROUP'], pointType.type)) {
                        SecFactory.getSessionProposalsByType(SecFactory.session.id, pointType.type)
                            .then(function(data) {
                                fixProposals(data);
                            }).catch(function(error) {
                                vm.proposals = [];
								vm.selectAllModel = false;
                        })
                    } else {
                        fixTitleAndObservations();
                    }
                }
            }

            vm.selectProposal = function($event, index) {
                var checkbox = $event.target;
                vm.proposals[index].selected = checkbox.checked;

				var count = $linq().count();
				if(count == vm.proposals.length){
					vm.selectAllModel = true;
				}else{
					vm.selectAllModel = false;
				}
            }
			vm.containerOrders = [
				{ id: 'T123', description: 'global.sec.literals.containerOrderT123' },
				{ id: 'T12', description: 'global.sec.literals.containerOrderT12' },
				{ id: 'T13', description: 'global.sec.literals.containerOrderT13' },
				{ id: 'T1', description: 'global.sec.literals.containerOrderT1' },
				{ id: 'T23', description: 'global.sec.literals.containerOrderT23' },
				{ id: 'T2', description: 'global.sec.literals.containerOrderT2' },
				{ id: 'T3', description: 'global.sec.literals.containerOrderT3' }
			];
            vm.requiredVoteStates = SecFactory.requiredVoteStates;
            vm.showVotes = false;
			this.$onInit = function () {
				if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.councillor_proposal_type && $rootScope.app.configuration.councillor_proposal_type.active) {
					vm.isActiveCouncillor = true;
					vm.delegationTypes = ((AdminFactory.councillorProposalTypeDelegationType && AdminFactory.councillorProposalTypeDelegationType.length > 0)?angular.copy(AdminFactory.councillorProposalTypeDelegationType):[]);
					vm.delegationTypes.unshift({language1: $filter('translate')('global.commonAdmin.literals.withoutCouncillorDelegationType'),language2: $filter('translate')('global.commonAdmin.literals.withoutCouncillorDelegationType'),language3: $filter('translate')('global.commonAdmin.literals.withoutCouncillorDelegationType'), id: undefined});
							
				}
				if(vm.weightedVote){
					vm.votationTypes = SecFactory.votationTypesWithWeightedType;
				}else{
					vm.votationTypes = SecFactory.votationTypes;
				}
			}
        }],
        bindings: {
            type: '<',
            proposals: '=?',
            pointTitle: '=?',
            pointObservations: '=?',
            documents: '=?',
            canEdit: '=?',
            votationType: '=?',
        	requiredVoteState:'=?',
			fromAdmin: '=?',
			proposalsContainer: '=?',
			containerOrder: '=?',
			delegationType: '=?',
			weightedVote: '=?'
        }
    })