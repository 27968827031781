angular
    .module('annexaApp')
    .component('annexaBoxSession', {
        templateUrl: './components/sec/annexa-box-session/annexa-box-session.html',
        controller:['AnnexaObjectBoxFactory', 'Language', '$rootScope', 'RestService', 'GlobalDataFactory', '$filter', 'AnnexaFormlyFactory', '$scope', '$timeout','SecFactory', function (AnnexaObjectBoxFactory, Language, $rootScope, RestService, GlobalDataFactory, $filter, AnnexaFormlyFactory, $scope, $timeout, SecFactory) {
            var vm = this;
            vm.langColumn = Language.getActiveColumn();
            $scope.$on('annexaAdvancedSearchModalAdd', function (event, args) {
                if(args.id == 'modalASSession') {
                    if(args.selected && args.selected.length > 0){
                    	var addedObjects = [];
                        var id = undefined;
                        _.forEach(args.selected, function (item) {
                    		if(id){
                        		id =id+','+item.id;
                        	}else{
                        		id = item.id;
                        	}
                        	addedObjects.push(item);
                        });
                        
                        if(addedObjects && addedObjects.length > 0){
                        	vm.search.searchedValue = { id: id, object: addedObjects };
                            vm.search.completeAdd();
                        }
                    	
                        args.modal.close();
                    }
                }
            });

            this.$onInit = function () {
                vm.permissions = AnnexaObjectBoxFactory.addNewViewPermissions(vm.permissions, 'view_sessions', 'view_sessions', vm.isEdit);

                vm.secModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.sec.proposals'").toArray();
            	
                if(!vm.boxTitle) {
                    vm.boxTitle = 'global.sec.literals.sessions';
                }

                if(vm.search) {
                    vm.search.origin = 'sec';
                    vm.search.type = 'Session';

                    vm.search.searchFunc = function(val) {
                        var added = $linq(vm.content).select(vm.search.addedSelect).toArray();

                        if (vm.entityId) {
                        	added.push(vm.entityId);
                        }
                        
                        var filter = {};

                        var additionalFilter = vm.search.additionalFilter ? vm.search.additionalFilter : {};
                        additionalFilter.loggedUser = $rootScope.LoggedUser.id;
                        additionalFilter.added = added;
                        additionalFilter.langColumn = Language.getActiveColumn();
                        additionalFilter.searchBox = val.val;
                        additionalFilter.isAdvanced = true;

                        return RestService.filterData(vm.search.origin, vm.search.type, filter, additionalFilter, 0, 10, '', 3, 'box')
                            .then(function (data) {
                                var response = [];

                                _.forEach(data.data.content, function(value, key) {
                                    response.push({
                                        id: value.id,
                                        object: JSOG.decode(value),
                                        value: vm.search.processSearch(JSOG.decode(value))
                                    });
                                });

                                response = new SortedArray(response, 'value').sort();

                                return response;
                            });

                    }


                    vm.search.processSearch = function(object) {
                        var value = ((object.sessionNumber)?object.sessionNumber: $filter('date')(new Date(object.sessionDateFirstConvene), 'dd/MM/yyyy HH:mm')) + ((object.organ && object.organ[vm.langColumn]) ? ' ' + object.organ[vm.langColumn] : '');
                        return value;
                    }

                    if(!vm.search.completeAdd) {
                        vm.search.completeAdd = function() {
                        	var newContent = [];
                        	if(vm.search.searchedValue && vm.search.searchedValue.object && Array.isArray(vm.search.searchedValue.object)){
                        		_.forEach(vm.search.searchedValue.object, function(obj){
                        			newContent.push(JSOG.decode(obj));
                        		});
                        	}else{
                        		newContent.push(JSOG.decode(vm.search.searchedValue.object));
                        	}

                    		_.forEach(newContent, function(obj){
                    			vm.content.push(obj);
                            });
                    		  
                    		vm.search.searchedValue = { id: -1, value: '' };
                            if(vm.search.saveSearch ) {
                                vm.search.saveSearch(newContent);
                            }
                        }
                    }

                    vm.search.advancedSearch = function() {
                        var getOrganWithPermissions =  $linq(GlobalDataFactory.organs).where(function(x){
                        	if($rootScope.LoggedUser && $rootScope.LoggedUser.userProfiles){
                        		if(x.everyoneCanViewSessions === false) {
                                    if(x.usersViewSessions && x.usersViewSessions.length > 0) {
                                        var intersectedViewUsers = $linq(x.usersViewSessions).intersect($rootScope.LoggedUser, "(x,y) => y.user && x.id && y.user.id && y.user.id == x.id").toArray();
                                        if(intersectedViewUsers && intersectedViewUsers.length > 0) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    } else {
                                        var intersectedProfiles = $linq(x.profiles).intersect($rootScope.LoggedUser.userProfiles, "(x,y) => x.profile && y.profile && x.profile.id && y.profile.id && x.profile.id == y.profile.id").toArray();
                                        if(intersectedProfiles && intersectedProfiles.length > 0){
                                            return true;
                                        }else{
                                            return false;
                                        }
                                    }
                                } else {
                                    return true;
                                }
                        	}else{
                        		return false;
                        	}
                        }).toArray();
                        
                        var sessionStatusRender = function(data, type, full, meta) {
                            var content = '';

                            if(SecFactory.sessionStates && SecFactory.sessionStates.length > 0) {
                                var state = $linq(SecFactory.sessionStates).singleOrDefault(undefined, "x => x.id == '" + data + "'");

                                if(state) {
                                    content += '<div class="text-center">';
                                    content += '    <span class="fa-stack" title=" ' + $filter('translate')(state.name) + '">';
                                    content += '        <i class="fa fa-' + state.icon + ' ' + state.style + ' text-lg fa-stack-1x"></i>';
                                    content += '    </span>';
                                    content += '</div>';
                                }
                            }

                            return content;
                        }
                        
                        var advancedSearchModal = {
                            title: 'global.literals.advanced_search_session',
                            size: 'modal-lg',
                            filter:[
                            	{ id: 'state', type: 'select-multiple', order: 3, label: 'global.literals.state', dataType: 'LOCAL', data: SecFactory.sessionStates, addAll: true, nameProperty: 'name', callAux: true},
                                { id: 'organ', type: 'select', order: 0, label: 'global.sec.literals.organ', dataType: 'LOCAL', data: getOrganWithPermissions, addAll: true, nameProperty: vm.langColumn, callAux: true},
                                { id: 'sessionDateFirstConvene', type: 'dateRange', order: 10, label: 'global.sec.literals.sessionDate', callAux: true },
                                { id: 'sessionType', type: 'select', order: 2, label: 'global.literals.type', dataType: 'LOCAL', data: GlobalDataFactory.sessionTypes, addAll: true, nameProperty: vm.langColumn, callAux: true },
                                { id: 'sessionNumber', type: 'text', order: 1, label: 'global.sec.literals.sessionNumber' }
                            ],
                            tableDefinition: {
                                id: 'tableAdvancedSearchSession',
                                origin: 'sec',
                                objectType: 'Session',
                                sortName: 'sessionNumber',
                                sort: [[5, 'asc']],
                                columns: [
                                    { id: 'state', width: '5%', render: sessionStatusRender, sortable: false},
                                    { id: 'organ', column: new DatabaseTranslatedParentColumn($filter, 'global.sec.literals.organ', vm.langColumn) },
                                    { id: 'sessionType', width: '15%', column: new DatabaseTranslatedParentColumn($filter, 'global.literals.type', vm.langColumn) },
                                    { id: 'sessionDateFirstConvene', width: '10%', column: new DateTimeColumn($filter, 'global.sec.literals.sessionDate')},
                                    { id: 'sessionNumber', width: '10%', column: new TextColumn($filter, 'global.sec.literals.sessionNumber')},
                                ],
                                noLoading: true,
                                skipFirstSearch: true,
                                callAuxToAdd: {added: $linq(vm.content).select("x => x.id").toArray()}
                            },
                            multiple: true
                        }
                        AnnexaFormlyFactory.showAnnexaAdvancedSearchModal('modalASSession', advancedSearchModal);
                    }
                }
            }
        }],
        bindings: {
            boxTitle: '@?',
            permissions: '=',
            search: '=',
            new: '=',
            content: '=',
            emptyText: '@',
            isEdit: '=',
            config: '=',
            origin: '@',
            entityId: '=?'
        }
    })
    .component('annexaBoxSessionRender', {
        templateUrl: './components/sec/annexa-box-session/annexa-box-session-render.html',
        require: {
            objectBoxComponent: '^^annexaObjectBox',
        },
        controller:['DialogsFactory', '$rootScope', 'Language', 'AnnexaPermissionsFactory', 'SecFactory', '$state', function(DialogsFactory, $rootScope, Language, AnnexaPermissionsFactory, SecFactory, $state) {
            var vm = this;
            vm.langColumn = Language.getActiveColumn();
            
            vm.redirect = function (id) {
                window.open($state.href('annexa.sec.sessions.view', { session: id }), '_blank');
            }
            
            vm.canShow = false;
            if(AnnexaPermissionsFactory.havePermission('view_sessions')) {
            	vm.canShow = true;
            }
                        
            vm.getStatusTitle = function(session){
            	var state = $linq(SecFactory.sessionStates).singleOrDefault(undefined, "x => x.id == 'PENDING'");
            	if(session && session.state){
            		state = $linq(SecFactory.sessionStates).singleOrDefault(undefined, "x => x.id == '"+session.state+"'");
            	}
            	if(state){
            		return state.name;
            	}else{
            		return '';
            	}
            }
            
            vm.getStatusIcon = function(session){
            	var state = $linq(SecFactory.sessionStates).singleOrDefault(undefined, "x => x.id == 'PENDING'");
            	if(session && session.state){
            		state = $linq(SecFactory.sessionStates).singleOrDefault(undefined, "x => x.id == '"+session.state+"'");
            	}
            	if(state){
            		return 'fa fa-'+state.icon+' '+state.style+' fa-lg';
            	}else{
            		return '';
            	}
            }
            vm.addedDocument = function(session){
            	if(vm.entityId){
            		if(vm.origin == 'annexa-document'){
		        		if(session && session.documents){
		        			var doc = $linq(session.documents).firstOrDefault(undefined,"x => x.document && x.document.id == "+vm.entityId);
		        			if(doc && doc.added){
		        				return true;
		        			}else{
		        				return false;
		        			}
		        		}else{
		        			return false;
		        		}
            		}else{
            			return true;
            		}
            	}else{
            		return true;
            	}
            }
            
            vm.delete = function(index) {
                DialogsFactory.confirm('global.literals.delete', 'global.literals.deleteSessionBox')
                    .then(function (data) {
                        var removedId = vm.content[index].id;

                        vm.content.splice(index, 1);

                        if(vm.isEdit) {
                            $rootScope.$broadcast('annexaBoxSessionDelete', { removedId: removedId, origin: vm.origin});
                        }
                    }).catch(function (data) {
                        //Empty
                });
            }
        }],
        bindings: {
            content: '=',
            emptyText: '@',
            isEdit: '=',
            origin: '@',
            entityId: '=?'
        }
    })