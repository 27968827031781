angular
    .module('annexaApp')
    .component('annexaBoxSecNotifications',{
        templateUrl: './components/sec/annexa-box-sec-notifications/annexa-box-sec-notifications.html',
        controller:['AnnexaFormlyFactory', 'NotificationFactory', '$scope', 'GlobalDataFactory', '$rootScope', function(AnnexaFormlyFactory, NotificationFactory, $scope, GlobalDataFactory, $rootScope) {
            var vm = this;
			vm.regOutputModule = [];
			vm.notificationModule = true;
			vm.boxTitle = 'global.literals.notifications';
            this.$onInit = function () {
                vm.regOutputModule = $linq(GlobalDataFactory.modules).where("x => x.url == 'annexa.reg.output'").toArray();
                if($rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.show_notification_functionality){
                    vm.notificationModule = $rootScope.app.configuration.show_notification_functionality.value;
                }
            };
        }],
        bindings: {
            permissions: '=',
            content: '=',
            emptyText: '@',
            showSessionSecNotifications: '=',
            publishSites: '='
        }
    })
    .component('annexaBoxSecNotificationsRender', {
        templateUrl: './components/sec/annexa-box-sec-notifications/annexa-box-sec-notifications-render.html',
        controller: ['$state', 'NotificationFactory', 'Language', 'AnnexaFormlyFactory', 'DialogsFactory','$filter', 'RestService', '$scope', 'SecFactory', 'HelperService', '$rootScope', 'GlobalDataFactory', 'globalModals', 'WizardHandler', 'DccumentsFactory', 'CustomFieldFactory', '$q', 'TramNewFactory', 'AnnexaDocumentActionsButtonFactory', function($state, NotificationFactory, Language, AnnexaFormlyFactory, DialogsFactory, $filter, RestService, $scope, SecFactory, HelperService, $rootScope, GlobalDataFactory, globalModals, WizardHandler, DccumentsFactory, CustomFieldFactory, $q, TramNewFactory, AnnexaDocumentActionsButtonFactory) {
            var vm = this;

            vm.languageColumn = Language.getActiveColumn();
            
            vm.showSecNotTable = false;
            vm.showSecNotDocTable = false;

            vm.secNotifications = [];
            
			vm.notificationGlobalStatus = angular.copy(NotificationFactory.notificationGlobalStatus);
            vm.notificationGlobalStatus.unshift({id: 'NO_SEND', name: $filter('translate')('NO_SEND'), class: 'text-grey', icon: 'circle', style: 'text-grey'});
            
            vm.documentStatuses = [
                { id: 'COMPLETE', name: $filter('translate')('global.documentState.COMPLETE'), class: 'fa fa-dot-circle-o text-success', nameLiteral: 'global.documentState.COMPLETE' },
                { id: 'NEW', name: $filter('translate')('global.documentState.NEW'), class: 'fa fa-circle-o text-blue', nameLiteral: 'global.documentState.NEW' },
                { id: 'NEW_AND_ATTACHMENT', name: $filter('translate')('global.documentState.NEW_AND_ATTACHMENT'), class: 'fa fa-adjust text-blue', nameLiteral: 'global.documentState.NEW_AND_ATTACHMENT' },
                { id: 'PENDING_SIGN', name: $filter('translate')('global.documentState.PENDING_SIGN'), class: 'fa fa-circle text-blue', nameLiteral: 'global.documentState.PENDING_SIGN' },
                { id: 'REJECTED', name: $filter('translate')('global.documentState.REJECTED'), class: 'fa fa-circle text-danger', nameLiteral: 'global.documentState.REJECTED' },
                { id: 'SIGNED', name: $filter('translate')('global.documentState.SIGNED'), class: 'fa fa-circle text-success', nameLiteral: 'global.documentState.SIGNED' },
                { id: 'ARCHIVED', name: $filter('translate')('global.documentState.ARCHIVED'), class: 'fa fa-circle-o text-success', nameLiteral: 'global.documentState.ARCHIVED' }
            ];
                
            vm.dossierTransactionStatus = [];
            vm.dossierTransactionStatus.push({id: 'NO_CREATED', name: $filter('translate')('NO_CREATED'), class: 'text-grey', icon: 'circle', style: 'text-grey'});
            vm.dossierTransactionStatus.push({id: 'IN_PROGRESS', name: $filter('translate')('IN_PROGRESS'), class: 'text-warn', icon: 'circle', style: 'text-warn'});
            vm.dossierTransactionStatus.push({id: 'FINISHED', name: $filter('translate')('FINISHED'), class: 'text-success', icon: 'circle', style: 'text-success'});
            
            vm.proposalId = (vm.content && vm.content[0] && vm.content[0].prop && vm.content[0].prop.id)?vm.content[0].prop.id:undefined;
            
            vm.viewNotification = function (notificationId) {
                 RestService.findOne('Notification', notificationId).then(function (data) {
                        var decodedData = JSOG.decode(data.data);
                    	var notificationViewModal = NotificationFactory.notificationViewModal(decodedData);

                        AnnexaFormlyFactory.showAnnexaFormModal('modalViewNotification', notificationViewModal);    
                    }).catch(function (error) {
                    	DialogsFactory.error('global.sec.literals.canNotOpenNotification')
                    });
            }

		    vm.getIconClassNotificationStatus = function (status) {
                switch (status) {
                    case 'PENDING':
                        return 'text-warn';
                    case 'SEND':
                        return 'text-info';
                    case 'COMPLETE':
                        return 'text-success';
                    case 'ERROR':
                        return 'text-danger';
                }

                return 'text-grey';
            }

			vm.getNameNotificationStatus = function (status) {
                switch (status) {
                    case 'PENDING':
                        return  $filter('translate')('PENDING');
                    case 'SEND':
                        return $filter('translate')('global.literals.SEND');
                    case 'COMPLETE':
                        return $filter('translate')('global.literals.COMPLETE');
                    case 'ERROR':
                        return $filter('translate')('global.literals.ERROR');
                }

                return $filter('translate')('NO_SEND');
            }
			
			vm.getStatusLiteral = function (status) {
				if(status){
					return $filter('translate')('global.literals.' + status);
				}
                return '';
			}
			
			vm.getNameNotificationType = function (data) {
				if(data && data[vm.languageColumn]){
					return data[vm.languageColumn];
				}
                return '';
			}
			
			vm.renderNotificationLastStatus = function (thirds) {
				if(thirds) {
		    		var select = []
		    		_.forEach(thirds, function(value){
		    			select = $linq(select).union(value.statuses).toArray();
                    });
				}
				if (select) { 
					var orderedByDateStatuses = $linq(select).orderBy("x => x.createdDate").toArray();
				}
				
	            return (orderedByDateStatuses != undefined && orderedByDateStatuses.length > 0) ?
	                orderedByDateStatuses[orderedByDateStatuses.length - 1].status[Language.getActiveColumn()] : '';
	        }
			
			vm.renderLastStatusUpdateNotifications = function(data) {
		    	var content = '';
		    	if(data && data.length > 0) {
		    		var select = []
		    		_.forEach(data, function(value){
		    			select = $linq(select).union(value.statuses).toArray();
		            });
		    		if (select) {
			    		var contentNoParce = $linq(select).orderByDescending("x => x.createdDate").first();
			    		if (contentNoParce) {
			    			var content =  $filter('date')(new Date(contentNoParce.createdDate), 'dd/MM/yyyy - HH:mm');
			    		}
		    		}
		    	}
		    	return content;
		    }

			vm.getIconClassTramStatus = function (dt) {
				if(dt && dt.id){
					if(dt.endDate){
						return 'success';
					}else{
						return 'warn';
					}
				}else{
					return 'text-grey';
				}
            }

			vm.getNameTramStatus = function (dt) {
                if(dt && dt.id){
					if(dt.endDate){
						return $filter('translate')('FINISHED');
					}else{
						return $filter('translate')('IN_PROGRESS');
					}
				}else{
					return $filter('translate')('NO_CREATED');
				}
            }
			
			vm.createDocuments = function(){
				var openDocument = function(secNotOk){
					if(secNotOk != null && secNotOk.length > 0){
						var openModalDocumentWithThirds = function(secNotOk){
							var steps = [];
							_.forEach(secNotOk, function(sn){
								if(sn && sn.docs && sn.docs.length > 0 && sn.relatedProposal && sn.relatedProposal.id){
									var sections = [];
									if(HelperService.isSuperAdmin()) {
						            	sections = GlobalDataFactory.sections;
						            } else {
						            	sections = $linq($rootScope.LoggedUser.userSections).select("x => x.section").toArray();
						            }
									var profiles = $linq($rootScope.LoggedUser.userProfiles).distinctBy("x => x.profile.id").select("x => x.profile").toArray();
						            var profilesExp = [];
									var defaultProfile = undefined;
									if(sn.relatedProposal && sn.relatedProposal.notificationTransaction && sn.relatedProposal.notificationTransaction.processProfiles && sn.relatedProposal.notificationTransaction.processProfiles.length > 0){
										profilesExp = $linq(profiles).intersect($linq(sn.relatedProposal.notificationTransaction.processProfiles).select("x => x.profile").toArray(), "(x, y) => x.id == y.id").toArray();
									}
									if(profilesExp && profilesExp.length > 0){
							            profilesExp =  new SortedArray(profilesExp, vm.languageColumn).sort();
										if ($rootScope.LoggedUser.userDefaultEntityProfiles) {
							                var profilesDef = $linq($rootScope.LoggedUser.userDefaultEntityProfiles).where("x => x.entityType == 'DOC'").select("x => x.profile").toArray();
							                if (profilesDef && profilesDef.length > 0) {
							                    defaultProfile = profilesDef[0];
							                }
							            }
						            }else{
										profilesExp = [];
									}
									_.forEach(sn.docs, function(doc, index){
										var hasThirds = ((doc.template && doc.template.allowThirds && sn.relatedProposal.notificationTransaction && sn.relatedProposal.notificationTransaction.dossier && sn.relatedProposal.notificationTransaction.dossier.thirds && sn.relatedProposal.notificationTransaction.dossier.thirds.length > 0)?true:false);
										var thirds = ((hasThirds)?sn.relatedProposal.notificationTransaction.dossier.thirds:[]);
										var modalSelectMultReceivers = function() {
						                	var modal = angular.copy(globalModals.selectMultipleReceiversCheckbox);
						                    modal.annexaFormly.model = {};
						                    modal.annexaFormly.model.modal_body = {};
						                    modal.annexaFormly.options = {};
						                    modal.annexaFormly.model.modal_body.selectValues = {};
                    						thirds = $linq(thirds).orderBy("x => x.third.completeName", linq.caseInsensitiveComparer).toArray();
                    						var selectableOptions = [];
                    						_.forEach(thirds, function (value, key) {
                    							modal.annexaFormly.model.modal_body.selectValues[value.id] = false;
                    						});
											if(step.annexaFormly.model && step.annexaFormly.model.seletctedThirds){
												_.forEach(step.annexaFormly.model.seletctedThirds, function (value, key) {
							                        modal.annexaFormly.model.modal_body.selectValues[value.id] = true;
							                    });
											}
                    						_.forEach(thirds, function (value, key) {
                        						var receiver = angular.copy(value);
                        						var name = ((receiver.third.name)?receiver.third.name+" ":"") + ((receiver.third.surename1)?receiver.third.surename1+" ":"") + ((receiver.third.surename2)? receiver.third.surename2+" " : "" ) + ((receiver.third.corporateName)?receiver.third.corporateName:"");
                        						var destinatariType = '';
                        						if(receiver.interested){
                        							var roleInterested = '';
                            						if(receiver.roleInterested) {
                            							roleInterested = '(' + receiver.roleInterested[Language.getActiveColumn()] + ')';
                            						}
                            						destinatariType = $filter('translate')('global.literals.interested', {  roleInterested: roleInterested });
                        						}else{
						                            var representated = '';
						                            if(value.representated && value.representated.identificationDocument){
						                                if(value.representated.name){
						                                    representated= '(' + value.representated.name + ' ' + value.representated.surename1 + ((value.representated.surename2)?' '+value.representated.surename2 : '' ) + ' (' + value.representated.identificationDocument + ')';
						                                }else{
						                                    representated= '(' + value.representated.corporateName + ' (' + value.representated.identificationDocument + ')';
						                                }
						                            }
						                            destinatariType = $filter('translate')('global.literals.representative', { representated: representated });
                        						}
						                        selectableOptions.push({ id: value.id, value: name, typeName: destinatariType});
                    						});
						                    modal.annexaFormly.model.modal_body.thirds = thirds;
                    						modal.annexaFormly.model.modal_body.selectableOptions = selectableOptions;
                    						modal.annexaFormly.fields[0].fieldGroup[3].templateOptions.options = selectableOptions;
						                    var submitFunction = function () {
                    							var selectedOptions = [];
                    							var slectedThirdsLabel = '';
                    							var labels = [];
                    							if(this.annexaFormly.model.modal_body.selectValues){
						                        	var keys = Object.keys(modal.annexaFormly.model.modal_body.selectValues);
						                            if(keys && keys.length > 0){
						                                _.forEach(keys, function(key){
					                                    	if(modal.annexaFormly.model.modal_body.selectValues[key]){
						                                    	var sel = $linq(thirds).firstOrDefault(function (x) { return x.id == parseInt(key); });
						                                    	selectedOptions.push(sel);
						                                    	var name = ((sel.third.name)?sel.third.name+" ":"") + ((sel.third.surename1)?sel.third.surename1+" ":"") + ((sel.third.surename2)? sel.third.surename2+" " : "" ) + ((sel.third.corporateName)?sel.third.corporateName:"");
						                                        var destinatariType = '';
						                                        if(sel.interested){
						                                        	var roleInterested = '';
						                                            if(sel.roleInterested) {
						                                            	roleInterested = '(' + sel.roleInterested[Language.getActiveColumn()] + ')';
						                                            }
						                                            destinatariType = $filter('translate')('global.literals.interested', {  roleInterested: roleInterested });
						                                        }else{
						                                            var representated = '';
						                                            if(sel.representated && sel.representated.identificationDocument){
						                                                if(sel.representated.name){
						                                                    representated= '(' + sel.representated.name + ' ' + sel.representated.surename1 + ((sel.representated.surename2)?' '+sel.representated.surename2 : '' ) + ' (' + sel.representated.identificationDocument + '))';
						                                                }else{
						                                                    representated= '(' + sel.representated.corporateName + ' (' + sel.representated.identificationDocument + '))';
						                                                }
						                                            }
						                                            destinatariType = $filter('translate')('global.literals.representative', { representated: representated });
						                                        }
						                                        slectedThirdsLabel = name + '[' + destinatariType + '] ';
		                				                        labels.push({label: name, type: destinatariType});
		                                				    }
	                                					});
	                            					}
	                        					}
												step.annexaFormly.fields[8].templateOptions.labels = labels;
												step.annexaFormly.model.seletctedThirds = selectedOptions;
					                    		this.close();
				                    		}
				                			AnnexaFormlyFactory.showModal("selectMultipleCheckbox", modal, submitFunction, false);
				                		}
										var step = {
											title: $filter('translate')('global.sec.literals.proposalDocuments')+': '+sn.relatedProposal.proposalNumber+' '+sn.relatedProposal.extract,
					                        key: 'docs'+sn.relatedProposal.id+'_'+index,
					                        disabled: false,
					                        disableNext: function(model, wizard) {
					                        	try{
						                    		var key = this.key;
						                    		var indexNextStep = $linq(wizard.steps).where("x => !x.disabled").indexOf(function (x) { return (x.key == key); });
						    	                    if (indexNextStep != undefined && indexNextStep > -1 && WizardHandler.wizard('modal-wizard') && WizardHandler.wizard('modal-wizard').currentStepNumber() == (indexNextStep + 1)) {
							                    		if(model && model.docTypeId && !model.origDocumentType){
							                    			if(model.modal_body_key && this.key && model.modal_body_key === this.key && !model.callToModifyCustomFields){
							                    				model.callToModifyCustomFields = true;
							                    				DccumentsFactory.modifyCustomFields(model.docTypeId, wizard.steps[indexNextStep].annexaFormly);
						                                    }
							                    		}
						    	                    }
						                    	}catch(e){
						                    		 console.log(e);
						                    	}
					                            var selected = false;
					                            if(wizard.steps[index].annexaFormly.form.$valid){
					                                selected = true;
					                            }
					                            return !selected;
											},
					                        annexaFormly: {
						 						fields: [
							                        {
						                                key: 'type',
						                                type: 'annexaLabelRow',
						                                className: 'col-sm-12',
						                                templateOptions: {
						                                    label: 'global.literals.type',
						                                    value:doc.type[vm.languageColumn],
							                        		modal_body_key:'docs'+sn.relatedProposal.id+'_'+index
						                                },
						                                data: { 
						                                    informed: true,
						                                    row: true
						                                }
						                            },
	                        						{
						                                key: 'template',
						                                type: 'annexaLabelRow',
						                                className: 'col-sm-12',
						                                templateOptions: {
						                                    label: 'global.literals.template',
						                                    value:doc.template[vm.languageColumn]
						                                },
						                                data: { 
						                                    informed: true,
						                                    row: true
						                                }
						                            },
	                        						{
						                                key: 'title',
						                                type: 'annexaLabelRow',
						                                className: 'col-sm-12',
						                                templateOptions: {
						                                    label: 'global.literals.title',
						                                    value:doc.name
						                                },
						                                data: { 
						                                    informed: true,
						                                    row: true
						                                }
						                            },
													{
						                                key: 'section',
						                                type: 'annexaSelectRow',
						                                className: 'col-sm-12',
						                                data: {
						                                    informed:true,
						                                    row:true,
						                                    clear: function($event,model,key, $select) {
						                                        $event.stopPropagation();
						                                        model[key] = undefined;
						                                        if($select) {
						                                            $select.selected = undefined;
						                                            $select.search = undefined;
						                                        }
						                                    }
						                                },
						                                templateOptions: {
						                                    optionsAttr: 'bs-options',
						                                    ngOptions: 'option in to.options | filter: $select.search',
						                                    label: 'global.literals.section',
						                                    valueProp: 'id',
						                                    labelProp: vm.languageColumn,
						                                    placeholder: '',
						                                    options: ((sections && sections.length > 0)?new SortedArray(sections, vm.languageColumn).sort():[]),
						                                    required: true,
						                                    focus: false
						                                },
						                                hideExpression: function($viewValue, $modelValue, scope) {
						                                	if(sections && sections.length === 1){
																scope.model.section = sections[0];
															}
															return (sections && sections.length === 1);
						                                }
						                            },
													{
						                                key: 'profiles',
						                                type: 'annexaMultipleSelectRow',
						                                className: 'col-sm-12',
						                                data: {
						                                    informed:true,
						                                    row:true,
						                                    clear: function($event,model,key, $select) {
						                                        $event.stopPropagation();
						                                        model[key] = undefined;
						                                        if($select) {
						                                            $select.selected = undefined;
						                                            $select.search = undefined;
						                                        }
						                                    }
						                                },
						                                templateOptions: {
						                                    optionsAttr: 'bs-options',
						                                    ngOptions: 'option in to.options | filter: $select.search',
						                                    label: 'global.literals.profiles',
						                                    valueProp: 'id',
						                                    labelProp: vm.languageColumn,
						                                    placeholder: '',
						                                    options: ((profilesExp && profilesExp.length > 0)?new SortedArray(profilesExp, vm.languageColumn).sort():[]),
						                                    required: true,
						                                    focus: false
						                                },
						                                hideExpression: function($viewValue, $modelValue, scope) {
															if(profilesExp && profilesExp.length === 1){
																scope.model.profiles = [profilesExp[0].id];
															}
						                                	return (profilesExp && profilesExp.length === 1);
						                                }
						                            },
	                        						{
						                                key: 'archiveClassification',
						                                type: 'annexaLabelRow',
						                                className: 'col-sm-12',
						                                templateOptions: {
						                                    label: 'global.literals.classificationBox',
						                                    value:doc.archiveClassification[vm.languageColumn]
						                                },
						                                data: { 
						                                    informed: true,
						                                    row: true
						                                }
						                            },
	                        						{
						                                key: 'thirdsName',
						                                type: 'annexaLabelRow',
						                                className: 'col-sm-12',
						                                templateOptions: {
						                                    label: 'global.literals.receivers'
						                                },
						                                data: { 
						                                    informed: true,
						                                    row: true
						                                },
						                                hideExpression: function($viewValue, $modelValue, scope) {
						                                	return !hasThirds;
						                                }
						                            },
						                            { 
						                                key: 'receiversBtn',
						                                type: 'annexaLabelButton',
						                                className: 'col-sm-12',
						                                templateOptions: {
						                                	buttonLabel: 'global.literals.addReceiversBtn',
						                                	executeFunction: modalSelectMultReceivers,
															atributes:"btn-sm",
															type:"text",
															validate:true,
															required: false,
															keystepId: 'docs'+doc.id
						                                },
						                                data: { 
						                                    row: true,
									                        colClass: 'col-sm-12',
									                        labelClass: 'label-strong',
									                        extralabel: true,
															informed: true
									                    },
						                                hideExpression:function($viewValue, $modelValue, scope){
						                                   return !hasThirds;
						                                }
						                            },
						                            { 
						                                key: 'receivers',
						                                type: 'annexaLabelsRow',
						                                className: 'col-sm-12',
						                                templateOptions: {
						                                    labels: []
						                                },
						                                data: { 
						                                    row: true,
									                        colClass: 'col-sm-12',
									                        labelClass: 'label-value',
									                        extralabel: true,
									                        type: 'HTMLOneModel'
									                    },
						                                hideExpression:function($viewValue, $modelValue, scope){
						                                   return !hasThirds;
						                                }
						                            },
					                                {
					                                    key: 'documentCustomFields',
					                                    className: 'col-sm-12',
					                                    templateOptions: {},
					                                    fieldGroup: [],
						                                data: { 
						                                    row: true,
									                        colClass: 'col-sm-12',
									                        labelClass: 'label-strong',
									                        extralabel: true,
															informed: true
									                    },
					                                    hideExpression: function ($viewValue, $modelValue, scope) {
					                                        if(scope.model && scope.model.documentCustomFieldsShow){
					                                            return false;
					                                        }else{
					                                            return true;
					                                        }
					                                    }
					                                }
												],
												model:{documentCustomFields: {},origDocumentType:undefined, modal_body_key: 'docs'+sn.relatedProposal.id+'_'+index, docTypeId:((doc && doc.type)?doc.type.id:undefined)}
											},
                							options:{},
											secNotification: sn,
											secNotificationDoc: doc,
											hasThirds: hasThirds
										};
										if(profilesExp && profilesExp.length > 0){
						                    if(defaultProfile) {
						                        if($linq(profilesExp).count("x => x.id == " + defaultProfile.id) > 0) {
						                            step.annexaFormly.model.profiles = [defaultProfile.id];
						                        }
						                    }
							            }
										if(sn.relatedProposal && sn.relatedProposal.notificationTransaction && sections && sections.length > 0
												&& sn.relatedProposal.notificationTransaction.dossier && sn.relatedProposal.notificationTransaction.dossier.section
												&& $linq(sections).contains(sn.relatedProposal.notificationTransaction.dossier.section, "(x, y) => x.id == y.id")) {
											step.annexaFormly.model.section = sn.relatedProposal.notificationTransaction.dossier.section;
										}
										if(hasThirds && thirds && thirds.length > 0){
											var thsAux = angular.copy(thirds);
							                var selectedOptionsAux = [];
							                var labelsAux = [];
							                if(thsAux && thsAux.length > 0){
							                	_.forEach(thsAux, function(thAux){
							                		var selectedThird = false;
							                		if(sn.relatedProposal.proposalNotificationThirdConfiguration && sn.relatedProposal.proposalNotificationThirdConfiguration.length > 0) {
							                			_.forEach(sn.relatedProposal.proposalNotificationThirdConfiguration, function(pntc){
							                				if(pntc.dossierThird.id == thAux.id) {
							                					selectedThird = true;
							                				}
							                			});
							                		} else {
							                			selectedThird = true;
							                		}
							                		if(thAux.notifiable && selectedThird){
							                			selectedOptionsAux.push(thAux);
							                        	var name = ((thAux.third.name)?thAux.third.name+" ":"") + ((thAux.third.surname1Particles)?thAux.third.surname1Particles+" ":"") + ((thAux.third.surename1)?thAux.third.surename1+" ":"") + ((thAux.third.surname2Particles)?thAux.third.surname2Particles+" ":"") + ((thAux.third.surename2)? thAux.third.surename2+" " : "" ) + ((thAux.third.corporateName)?thAux.third.corporateName:"");
							                			var destinatariType = '';
							                            if(thAux.interested){
							                            	var roleInterested = '';
							                                if(thAux.roleInterested) {
							                                	roleInterested = '(' + thAux.roleInterested[Language.getActiveColumn()] + ')';
							                                }
							                                destinatariType = $filter('translate')('global.literals.interested', {  roleInterested: roleInterested });
							                            }else{
							                            	var representated = '';
							                                if(thAux.representated && thAux.representated.identificationDocument){
							                                    if(thAux.representated.name){
							                                        representated= '(' + thAux.representated.name + ((thAux.representated.surname1Particles)?' '+ thAux.representated.surname1Particles : '') + ' ' + thAux.representated.surename1 + ((thAux.representated.surname2Particles)?' '+ thAux.representated.surname2Particles : '') + ((thAux.representated.surename2)?' '+thAux.representated.surename2 : '') + ' (' + thAux.representated.identificationDocument + '))';
							                                    }else{
							                                        representated= '(' + thAux.representated.corporateName + ' (' + thAux.representated.identificationDocument + '))';
							                                    }
							                                }
							                                destinatariType = $filter('translate')('global.literals.representative', { representated: representated });
							                            }
							                            labelsAux.push({label: name, type: destinatariType});
							                		}
							                	});
							                }
							                if(labelsAux && labelsAux.length > 0 && step && step.annexaFormly && step.annexaFormly.fields){
							                	var receiversField = $linq(step.annexaFormly.fields).firstOrDefault(undefined, "x => x.key == 'receivers'");
							                	if(receiversField && receiversField.templateOptions){
							                		receiversField.templateOptions.labels = $linq(labelsAux).orderBy("x => x.label", linq.caseInsensitiveComparer).toArray();
							                	}
							                	step.annexaFormly.model.seletctedThirds = selectedOptionsAux;
							                } 
										}
										steps.push(step);
									});
								}
							});
							if(steps && steps.length > 0){
								var finishCreateDocuments = function(){
									var selfFinishCreateDocument = this;
									var fromTemplateRequests = {};
									if(this.wizard && this.wizard.steps){
										var noErrors = true;
										var calculateCFTGroups = function(newGroups){
					                    	var groups = [];
					                    	if(newGroups){
					                    		_.forEach(newGroups, function(ng){
					                    			_.forEach(ng.groups, function(g){
					                    				g.groups = calculateCFTGroups(g.newGroups); 
					                    				g.relatedCustomField = ((g.relatedCustomField && g.relatedCustomField.id)?{id:g.relatedCustomField.id}:undefined);
					                    				groups.push(g);
					                    			})
					                    		})
					                    	}
					                    	return groups;
					                    }
					                	_.forEach(this.wizard.steps, function(step){
											if(step && step.secNotification && step.secNotification.relatedProposal && step.secNotification.relatedProposal.id && step.secNotification.relatedProposal.notificationTransaction && step.secNotification.relatedProposal.notificationTransaction.dossier && step.secNotification.relatedProposal.notificationTransaction.dossier.id){
												if(!fromTemplateRequests[step.secNotification.relatedProposal.id]){
													fromTemplateRequests[step.secNotification.relatedProposal.id] = [];
												}
												if(step.hasThirds && step.annexaFormly && step.annexaFormly.model && (!step.annexaFormly.model.seletctedThirds || step.annexaFormly.model.seletctedThirds.length === 0)){
													modalCreateDocuments.alerts.push({msg: 'global.errors.noDestinataris'});
													noErrors = false;
												}else{
													var fromTemplateRequest = {};
													var thirdsSelected = [];
													_.forEach(step.annexaFormly.model.seletctedThirds, function(th){
														thirdsSelected.push({id:th.id, dossier:{id:((th.dossier && th.dossier.id && th.dossier.id !== step.secNotification.relatedProposal.notificationTransaction.dossier.id)?th.dossier.id:step.secNotification.relatedProposal.notificationTransaction.dossier.id)}, third:{id:th.third.id}});
													});	
													fromTemplateRequest.thirdsSelected = thirdsSelected;
													fromTemplateRequest.tramTemplate = ((step.secNotificationDoc && step.secNotificationDoc.template && step.secNotificationDoc.template.id)?step.secNotificationDoc.template.id:undefined);
													fromTemplateRequest.title = ((step.secNotificationDoc && step.secNotificationDoc.name)?step.secNotificationDoc.name:'');
													fromTemplateRequest.section = ((step.annexaFormly && step.annexaFormly.model && step.annexaFormly.model.section && step.annexaFormly.model.section.id)?step.annexaFormly.model.section.id:undefined);
													fromTemplateRequest.profiles = ((step.annexaFormly && step.annexaFormly.model && step.annexaFormly.model.profiles)?step.annexaFormly.model.profiles:[]);
													fromTemplateRequest.archiveClassification = ((step.secNotificationDoc && step.secNotificationDoc.archiveClassification && step.secNotificationDoc.archiveClassification.id)?{id:step.secNotificationDoc.archiveClassification.id}:undefined);
													fromTemplateRequest.type = ((step.secNotificationDoc && step.secNotificationDoc.type && step.secNotificationDoc.type.id)?{id:step.secNotificationDoc.type.id}:undefined);
													fromTemplateRequest.transaction = ((step.secNotification.relatedProposal.notificationTransaction)?{id:step.secNotification.relatedProposal.notificationTransaction.id, dossier:{id:step.secNotification.relatedProposal.notificationTransaction.dossier.id}}:undefined);
													fromTemplateRequest.user = (($rootScope.LoggedUser)?{id:$rootScope.LoggedUser.id}:undefined);
													fromTemplateRequest.langColumn = vm.languageColumn;
									                fromTemplateRequest.translations = {
									                    "third_relation_interested": $filter('translate')('global.literals.interested'),
									                    "third_relation_representative": $filter('translate')('global.literals.representative'),
									                    "yes": $filter('translate')('global.literals.yes'),
									                    "no": $filter('translate')('global.literals.no')
									                };
													var cf = CustomFieldFactory.getModelValues(step.annexaFormly.model.documentCustomFields);
													fromTemplateRequest.customFields = [];
													var documentType = $linq(GlobalDataFactory.documentTypes).singleOrDefault(undefined, "x => x.id == " + fromTemplateRequest.type.id);
                        							if(documentType && cf && cf.length > 0) {
														_.forEach(documentType.customFields, function(docTypeField) {
															var groupField = undefined;
															if(step && step.annexaFormly && step.annexaFormly.fields){
																var docCFFields = $linq(step.annexaFormly.fields).firstOrDefault(undefined, "x => x.key == 'documentCustomFields'");
																if(docCFFields && docCFFields.fieldGroup){
																	groupField = $linq(docCFFields.fieldGroup).firstOrDefault(undefined, "x => x.key == 'cf_"+documentType.id+"_"+docTypeField.customField.id+"'");
																}
															}
							                            	var docCustomField = undefined;
							                            	if(groupField && groupField.data && groupField.data.customField){
							                            		docCustomField = angular.copy(groupField.data.customField);
							                            	}else{
							                            		docCustomField = angular.copy(docTypeField);	
							                            	}
															docCustomField.id = undefined;
															docCustomField.customField = { id: docTypeField.customField.id };
															docCustomField.documentType = { id: documentType.id };
															docCustomField.groups = calculateCFTGroups(docCustomField.newGroups);
															var cfValue = $linq(cf).singleOrDefault(undefined, "x => x.id == 'cf_" + documentType.id  + "_" + docTypeField.customField.id + "'");
															if(cfValue) {
							                                    if(docTypeField.customField.frontendType == 'CHECKBOX'){
							                                        var custom_field_selected = $linq(cf).where(function(x){
							                                            if(x.id.startsWith(cfValue.id+"_")){return true}else{return false}}
							                                        ).toArray();
							                                        if(custom_field_selected && custom_field_selected.length > 0){
							                                            docCustomField.value = '[';
							                                            _.forEach(custom_field_selected, function (item, key) {
							                                                if(item.value) {
							                                                    if(docTypeField.customField && docTypeField.customField.listValues && docTypeField.customField.listValues.length > 0) {
							                                                        var custom_field_value_selected = $linq(docTypeField.customField.listValues).where(function(x){
							                                                            if(item.id.endsWith("_"+x.value)){return true}else{return false}}
							                                                        ).toArray();
							                                                        if(custom_field_value_selected && custom_field_value_selected.length > 0){
							                                                            docCustomField.value += ((docCustomField.value == '[') ?custom_field_value_selected[0].value : ',' + custom_field_value_selected[0].value);
							                                                        }
							                                                    }
							                                                }
							                                            });
							                                            docCustomField.value += ']';
							                                        }
							                                    }else if(Array.isArray(cfValue.value)) {
							                                        if(docTypeField.customField.frontendType == 'MULTIPLESELECT') {
							                                            docCustomField.value = '[';
							
							                                            _.forEach(cfValue.value, function (item) {
							                                                docCustomField.value += ((docCustomField.value == '[') ? item : ',' + item);
							                                            });
							
							                                            docCustomField.value += ']';
							                                        } else {
							                                            docCustomField.value = cfValue.value[0];
							                                        }
							                                    } else {
							                                        if(cfValue.value instanceof Date) {
							                                            docCustomField.value = $filter('date')(cfValue.value,'yyyy-MM-ddTHH:mm:ss');
							                                        } else {
							                                            docCustomField.value = cfValue.value;
							                                        }
							                                    }
							                                }
							                                fromTemplateRequest.customFields.push(docCustomField);

														});
                        							}
												  	fromTemplateRequests[step.secNotification.relatedProposal.id].push(JSOG.encode(fromTemplateRequest));
												}
											}
										});
										if(noErrors){
											SecFactory.generateSecNotificationDocuments(-1, fromTemplateRequests).then(function(dataCall, status) {
												if(dataCall){
													SecFactory.showErrors(secNotOk, dataCall, 'global.sec.literals.okDocs', 'global.sec.literals.koDocs', true);
													selfFinishCreateDocument.close();
												} else {
													selfFinishCreateDocument.close();
													$state.reload();
												}	
											}).catch(function(error) {
												DialogsFactory.error($filter('translate')('global.sec.literals.errorSecNotfication'));
									        });
										}
									}
									
								}
								var modalCreateDocuments = angular.copy(globalModals.secNotificationCreateDocument);
								modalCreateDocuments.wizard.steps = steps;
								modalCreateDocuments.wizard.modal = modalCreateDocuments;
								AnnexaFormlyFactory.showModal('fromScannerWizardModal', modalCreateDocuments, finishCreateDocuments, false, true);
							}								
						}
						var propsWithSons = $linq(secNotOk).where("x => x.relatedProposal && x.relatedProposal.workWithDossierSons").select("x => x.relatedProposal.id").toArray();
						if(propsWithSons && propsWithSons.length > 0){
							SecFactory.getDossierThirdsAndSons(propsWithSons, undefined).then(function(dataThirdsAndSons, status) {
								var secNotOkAux = []; 
								_.forEach(secNotOk, function(sn){
									var snAux = angular.copy(sn);
									if(sn.relatedProposal && sn.relatedProposal.id && sn.relatedProposal.notificationTransaction && sn.relatedProposal.notificationTransaction.dossier && dataThirdsAndSons[sn.relatedProposal.id]){
										snAux.relatedProposal.notificationTransaction.dossier.thirds = dataThirdsAndSons[sn.relatedProposal.id];
									}
									secNotOkAux.push(snAux);
								});
								openModalDocumentWithThirds(secNotOkAux);
							}).catch(function(error) {
								openModalDocumentWithThirds(secNotOk);
					        });		
						}else{
							openModalDocumentWithThirds(secNotOk);
						}
					}
				}
				
				SecFactory.getSecNotificationsConfigurationProposal("DOCUMENT", vm.proposalId).then(function(data, status) {
					var dataAux =data;
					if(dataAux && dataAux.length > 0){
						openDocument(dataAux)
					}else{
						DialogsFactory.error($filter('translate')('global.sec.literals.errorget'));
					}
				}).catch(function(error) {
					DialogsFactory.error($filter('translate')('global.sec.literals.errorget'));
		        });
			}
			
			vm.sendToSignDocuments = function(){
				var sendToSignModal = function(secNotOk){
					if(secNotOk != null && secNotOk.length > 0){
						var documentsWithSNot = [];
						var selectedDocuments = [];
						_.forEach(secNotOk, function(sn){
							if(sn && sn.docs && sn.docs.length > 0 && sn.relatedProposal && sn.relatedProposal.id){
								if(sn.relatedProposal && sn.relatedProposal.workWithDossierSons && sn.relatedProposal.workWithDossierSons === true){
									if(sn.allDocuments && sn.allDocuments.length > 0){
										_.forEach(sn.allDocuments, function(doc){
											if(doc.generateFromSecNotification && doc.document && doc.document.id && _.contains(['NEW_AND_ATTACHMENT', 'COMPLETED'],doc.document.docStatus) && !$linq(selectedDocuments).firstOrDefault(undefined, "x => x.id == "+doc.document.id)){
												if(doc.proposalId && sn.relatedProposal.id == doc.proposalId) {
													documentsWithSNot.push({document:doc.document, sn: sn});
													selectedDocuments.push({ id: doc.document.id, value: doc.document.name+" - ("+sn.relatedProposal.proposalNumber+")", typeName:''});
												} else if(!doc.proposalId){
													documentsWithSNot.push({document:doc.document, sn: sn});
													selectedDocuments.push({ id: doc.document.id, value: doc.document.name+" - ("+sn.relatedProposal.proposalNumber+")", typeName:''});
												}
		                            		}
										});				
									}
								}else{
									if(sn.relatedProposal && sn.relatedProposal.notificationTransaction && sn.relatedProposal.notificationTransaction.documents && sn.relatedProposal.notificationTransaction.documents.length > 0){
										_.forEach(sn.relatedProposal.notificationTransaction.documents, function(doc){
											if(doc.generateFromSecNotification && doc.document && doc.document.id && _.contains(['NEW_AND_ATTACHMENT', 'COMPLETED'],doc.document.docStatus) && !$linq(selectedDocuments).firstOrDefault(undefined, "x => x.id == "+doc.document.id)){
												if(doc.proposalId && sn.relatedProposal.id == doc.proposalId) {
													documentsWithSNot.push({document:doc.document, sn: sn});
													selectedDocuments.push({ id: doc.document.id, value: doc.document.name+" - ("+sn.relatedProposal.proposalNumber+")", typeName:''});
												} else if(!doc.proposalId){
													documentsWithSNot.push({document:doc.document, sn: sn});
													selectedDocuments.push({ id: doc.document.id, value: doc.document.name+" - ("+sn.relatedProposal.proposalNumber+")", typeName:''});
												}
		                            		}
										});				
									}
								}
							}
						});
						if(documentsWithSNot && documentsWithSNot.length > 0){
							var finishSendToSignDocuments = function(){
								var selfModalSendToSignDocuments = this;
								if(this.annexaFormly.model && this.annexaFormly.model.secNotificationsDocuments){
									var keys = Object.keys(this.annexaFormly.model.secNotificationsDocuments);
									if(keys && keys.length > 0){
										var mapSelected = {};
										var noPresetSelected = false;
										var added = false;
										_.forEach(keys, function(key){
											var doc = $linq(documentsWithSNot).firstOrDefault(undefined, "x => x.document && x.document.id == "+ Number(key));
											if(doc && doc.document && doc.document.id && doc.sn && doc.sn.relatedProposal && doc.sn.relatedProposal.id){
												if(mapSelected && !mapSelected[doc.sn.relatedProposal.id]){
													mapSelected[doc.sn.relatedProposal.id] = [];
												} 
												if(selfModalSendToSignDocuments.annexaFormly.model.secNotificationsDocuments[key] && documentsWithSNot){
													if(!noPresetSelected && selfModalSendToSignDocuments.annexaFormly.model['presetSign'+doc.document.id] && selfModalSendToSignDocuments.annexaFormly.model['presetSign'+doc.document.id].id){
														var snAux = angular.copy(doc.sn);
														snAux.relatedProposal = undefined;
														snAux.relatedProposal = {id:doc.sn.relatedProposal.id}
														var toSign = {
															secNotification: snAux,
															document: {id: doc.document.id},
															presetSignCircuit:{id:selfModalSendToSignDocuments.annexaFormly.model['presetSign'+doc.document.id].id}
														};
														mapSelected[doc.sn.relatedProposal.id].push(toSign);
														added = true;
													}else{
														noPresetSelected = true;
														mapSelected = undefined
														added = false;
													}
												}
											}
										});
										if(noPresetSelected){
											DialogsFactory.error($filter('translate')('global.sec.literals.errorSecNotficationNoPresetSelect'));
										}else if(added){
											SecFactory.sendToSignSecNotificationDocuments(-1, mapSelected).then(function(dataCall, status) {
												if(dataCall){
													SecFactory.showErrors(secNotOk, dataCall, 'global.sec.literals.okSign', 'global.sec.literals.koSign', true);
													selfModalSendToSignDocuments.close();
												} else {
													selfModalSendToSignDocuments.close();
													$state.reload();
												}
											}).catch(function(error) {
												DialogsFactory.error($filter('translate')('global.sec.literals.errorSecNotfication'));
									        });
										}else{
											DialogsFactory.error($filter('translate')('global.sec.literals.errorSecNotficationNoDocuments'));
										}
									}else{
										DialogsFactory.error($filter('translate')('global.sec.literals.errorSecNotficationNoDocuments'));
									}
								}else{
									DialogsFactory.error($filter('translate')('global.sec.literals.errorSecNotficationNoDocuments'));
								}
							}
							
							var modalSendToSignDocuments = angular.copy(globalModals.secNotificationSendToSign);
							modalSendToSignDocuments.annexaFormly.model = {secNotificationsDocuments:{}};
						    _.forEach(selectedDocuments, function (value2, key2) {
	                        	modalSendToSignDocuments.annexaFormly.model.secNotificationsDocuments[value2.id] = true;
	                        });
						    var secNotificationsDocumentsField = $linq(modalSendToSignDocuments.annexaFormly.fields).firstOrDefault(undefined, "x => x.key == 'secNotificationsDocuments'");
				            if(secNotificationsDocumentsField && secNotificationsDocumentsField.templateOptions){
								secNotificationsDocumentsField.templateOptions.options = selectedDocuments;
							}
			                var promises = [];
			                var docTemplSignCircuit = [];
							_.forEach(documentsWithSNot, function(doc){
								var field = {
	                                key: 'presetSign'+doc.document.id,
	                                type: 'annexaSelectRow',
	                                className: 'col-sm-12',
	                                data: {
	                                    informed:true,
	                                    row:true,
	                                    clear: function($event,model,key, $select) {
	                                        $event.stopPropagation();
	                                        model[key] = undefined;
	                                        if($select) {
	                                            $select.selected = undefined;
	                                            $select.search = undefined;
	                                        }
	                                    }
	                                },
	                                templateOptions: {
	                                    optionsAttr: 'bs-options',
	                                    ngOptions: 'option in to.options | filter: $select.search',
	                                    label: $filter('translate')('global.literals.presetSignCircuits') +" - "+doc.document.name+" ("+doc.sn.relatedProposal.proposalNumber+")",
	                                    valueProp: 'id',
	                                    labelProp: vm.languageColumn,
	                                    placeholder: '',
	                                    options: [],
	                                    required: true,
	                                    focus: false
	                                },
	                                hideExpression: function($viewValue, $modelValue, scope) {
										return (!scope.model.secNotificationsDocuments[doc.document.id]);
	                                }
	                            }
								if(doc.document.tramTemplate && doc.document.tramTemplate.templateSignCircuit && doc.document.tramTemplate.templateSignCircuit.length > 0){
		                        	promises.push(RestService.findByUrl('./api/doc/templateSignCircuit/getTemplateSignCircuit/' + doc.document.id + '/tramTemplate'));
		                        	docTemplSignCircuit.push({docId: doc.document.id, templProp: 'tramTemplate'});
								}else{
									if(doc.document.type && doc.document.type.presetSignCircuits && doc.document.type.presetSignCircuits.length > 0){
										if(doc.document.type.presetSignCircuits.length > 1){
											field.templateOptions.options = doc.document.type.presetSignCircuits;
											modalSendToSignDocuments.annexaFormly.fields.push(field);
										}else{
											modalSendToSignDocuments.annexaFormly.model['presetSign'+doc.document.id] = doc.document.type.presetSignCircuits[0];
										}	
									}
								}
							});
							
							$q.all(promises).then(function(data) {
			                	
			                	for(var i = 0; i < promises.length; i++) {
			                		var doc = $linq(documentsWithSNot).firstOrDefault(undefined, "x => x.document.id == " + docTemplSignCircuit[i].docId);
			                		if(doc) {
			                			var tsc = $linq(doc.document[docTemplSignCircuit[i].templProp].templateSignCircuit).firstOrDefault(undefined, "x => x.id == " + data[i].data.id);
			    	                    modalSendToSignDocuments.annexaFormly.model['presetSign'+doc.document.id] = tsc.presetSignCircuit;
			                		}
			                    }

								AnnexaFormlyFactory.showModal('fromScannerWizardModal', modalSendToSignDocuments, finishSendToSignDocuments, false);
		                    }).catch(function(error) {});
						}
					}
					
				}
				
				SecFactory.getSecNotificationsConfigurationProposal("SIGN", vm.proposalId).then(function(data, status) {
					var dataAux =data;
					if(dataAux && dataAux.length > 0){
						sendToSignModal(dataAux)
					}else{
						DialogsFactory.error($filter('translate')('global.sec.literals.errorget'));
					}
				}).catch(function(error) {
					DialogsFactory.error($filter('translate')('global.sec.literals.errorget'));
		        });
			}
			
			vm.createNotifications = function(){
				var createNotificationsModal = function(secNotOk){
					if(secNotOk != null && secNotOk.length > 0){
						var createNotificationsModalFinal = function(secNotOk){
							var validDocStatus = ["SIGNED","COMPLETE"];
			        		var validRelationType = ["DOCUMENT","WORK_DOCUMENT"];
				        	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.notification_valid_document_status) {
				        		validDocStatus = $rootScope.app.configuration.notification_valid_document_status.status;
				        		validRelationType = $rootScope.app.configuration.notification_valid_document_status.relation_type;
				        	}
					    	var haveAllNotDocs = true;
							_.forEach(secNotOk, function (value2, key2) {
                            	if(value2.allDocuments && value2.allDocuments.length > 0){
                            		var totalNotDocs = 0;
									var docsOk = 0;
									_.forEach(value2.allDocuments, function(doc){
										if(doc.generateFromSecNotification){
											if(!doc.proposalId || (doc.proposalId && value2.relatedProposal.id == doc.proposalId)) {
												totalNotDocs++;
												if(_.contains(validDocStatus, doc.document.docStatus) && _.contains(validRelationType, doc.relationType) && doc.document.type && doc.document.type.includeNotification && !doc.document.physical) {
													docsOk++;
												}
											}
										}
									});
									haveAllNotDocs = totalNotDocs == docsOk;
								}
	                        });
							if(!haveAllNotDocs) {
								DialogsFactory.error($filter('translate')('global.sec.literals.secNotificationProposalWithoutDocs'));
							} else {
								var steps = [];
								_.forEach(secNotOk, function(sn, index){
									var validDocStatus = ["SIGNED","COMPLETE"];
					        		var validRelationType = ["DOCUMENT","WORK_DOCUMENT"];
						        	if($rootScope && $rootScope.app && $rootScope.app.configuration && $rootScope.app.configuration.notification_valid_document_status) {
						        		validDocStatus = $rootScope.app.configuration.notification_valid_document_status.status;
						        		validRelationType = $rootScope.app.configuration.notification_valid_document_status.relation_type;
						        	}
					        		var documentsWithSNot = [];
									var selectedDocuments = [];
									var thirdsWithSNot = [];
									var selectedThirds = [];
									var thirdsEnabled = {};
									var dtWithoutThirds = [];
					            	var documentsIdSelected = {};
					            	var certDocuments = [];
									if(sn.relatedProposal && sn.relatedProposal.notificationTransaction && sn.relatedProposal.notificationTransaction.dossier && sn.relatedProposal.notificationTransaction.dossier.thirds && sn.relatedProposal.notificationTransaction.dossier.thirds.length > 0){
										thirdsWithSNot = $linq(sn.relatedProposal.notificationTransaction.dossier.thirds).where("x => x.notifiable === true").toArray();
										if(thirdsWithSNot && thirdsWithSNot.length > 0){
											_.forEach(thirdsWithSNot, function(thwsn){
												selectedThirds.push(thwsn.third.id);
											});
										}
									}
									if(sn.allDocuments && sn.allDocuments.length > 0){
										_.forEach(sn.allDocuments, function(doc){
										if(_.contains(validDocStatus, doc.document.docStatus) && _.contains(validRelationType, doc.relationType) && doc.document.type && doc.document.type.includeNotification && !doc.document.physical){
												doc.document.dossierTransactionDocumentId = doc.id;
												if(doc.certificateFromThisProposal){
													doc.document.name = ((doc.document.name)?doc.document.name:'')+' ('+sn.relatedProposal.proposalNumber+')';
													certDocuments.push(doc);
												}
												documentsWithSNot.push(doc.document);
												if(!doc.dossierThird || !doc.dossierThird.third || !doc.dossierThird.third.id){
													dtWithoutThirds.push(doc);
												}
												if(doc.generateFromSecNotification){
													if(doc.proposalId && sn.relatedProposal.id == doc.proposalId) {
														selectedDocuments.push(doc);
													} else if(!doc.proposalId){
														selectedDocuments.push(doc);
													}
												}
											}
										});
									}
									if(thirdsWithSNot && thirdsWithSNot.length > 0 && sn.allDocuments){
										_.forEach(thirdsWithSNot, function(third){
											var docs = $linq(sn.allDocuments).distinctBy("x => x.document.id").where("x => x.document.type.includeNotification == true && x.dossierThird && x.dossierThird.id == "+third.id).select("x => x.document.id").toArray();
											thirdsEnabled[third.id] = {enabled:true, docs:docs};
										});
									}
									if(sn.relatedProposal && sn.relatedProposal.proposalNotificationThirdConfiguration && sn.relatedProposal.proposalNotificationThirdConfiguration.length > 0) {
					            		_.forEach(sn.relatedProposal.proposalNotificationThirdConfiguration, function(pntc){
											documentsIdSelected[pntc.dossierThird.id] = pntc.proposalNotificationThirdDocumentConfiguration;
											if(certDocuments && certDocuments.length > 0) {
												_.forEach(certDocuments, function(cd){
													documentsIdSelected[pntc.dossierThird.id].push(cd);
												});
											}
										});
					            		_.forEach(thirdsWithSNot, function(third){
											third.selected = false;
											if(documentsIdSelected && documentsIdSelected[third.id]) {
	                                            _.forEach(selectedDocuments, function(cd){
	                                            	if(cd.dossierThird && cd.dossierThird.id && cd.dossierThird.id == third.id) {
		                                                documentsIdSelected[third.id].push(cd);
	                                            	} else if (!cd.dossierThird || !cd.dossierThird.id) {
		                                                documentsIdSelected[third.id].push(cd);
	                                            	}
	                                            });
												third.selected = true;
											}
										});
					            	} else {
					            		_.forEach(thirdsWithSNot, function(third){
					            			_.forEach(selectedDocuments, function(cd){
                                            	if(cd.dossierThird && cd.dossierThird.id && cd.dossierThird.id == third.id) {
	                                                documentsIdSelected[third.id].push(cd);
                                            	} else if (!cd.dossierThird || !cd.dossierThird.id) {
	                                                documentsIdSelected[third.id].push(cd);
                                            	}
                                            });
										});
					            	}
									var sections = [];
									if(HelperService.isSuperAdmin()) {
						            	sections = GlobalDataFactory.sections;
						            } else {
						            	sections = $linq($rootScope.LoggedUser.userSections).select("x => x.section").toArray();
						            }
									var showTramClassification = false;
						            var requiredTramClassification = false;
						            if($rootScope.app.configuration.tram_classification_register_output) {
						        		requiredTramClassification = $rootScope.app.configuration.tram_classification_register_output.required;
						        		showTramClassification = $rootScope.app.configuration.tram_classification_register_output.show;
						        	}
						            if(showTramClassification) {
						            	var defaultTramClassification = undefined;
						            	if(sn.relatedProposal && sn.relatedProposal.notificationTransaction && sn.relatedProposal.notificationTransaction.dossier && sn.relatedProposal.notificationTransaction.dossier.tramClassification) {
						            		defaultTramClassification = sn.relatedProposal.notificationTransaction.dossier.tramClassification;
						            	}
						            }	
									var step = {
										title: $filter('translate')('global.literals.new_notification_title')+': '+sn.relatedProposal.proposalNumber+' '+sn.relatedProposal.extract,
										key: 'not'+sn.relatedProposal.id+'_'+index,
										disabled: false,
										disableNext: function(model, wizard) {
											var selected = false;
											if(wizard.steps[index].annexaFormly.form.$valid){
												selected = true;
											}
											return !selected;
										},
										annexaFormly: {
											fields: [
												{
					                                key: 'thirds',
					                                type: 'annexaComponent',
					                                templateOptions: {
					                                    type: 'annexa-select-thirds-documents',
					                                    first: true
					                                },
					                                data: {
					                                    title: 'global.literals.receivers',
								                        thirds: thirdsWithSNot,
														removeAddressesWithoutSelectedNotificationType: true,
														thirdsEnabled: thirdsEnabled,
														selectedThirds: selectedThirds,
								                        titleDocuments: 'global.publishSite.literals.attachedDocuments',
								                        documents: documentsWithSNot,
								                        documentsIdSelected: documentsIdSelected
					                                }            
												},
												{
					                                key: 'section',
					                                type: 'annexaSelectRow',
					                                className: 'col-sm-12',
					                                data: {
					                                    informed:true,
					                                    row:true,
					                                    clear: function($event,model,key, $select) {
					                                        $event.stopPropagation();
					                                        model[key] = undefined;
					                                        if($select) {
					                                            $select.selected = undefined;
					                                            $select.search = undefined;
					                                        }
					                                    }
					                                },
					                                templateOptions: {
					                                    optionsAttr: 'bs-options',
					                                    ngOptions: 'option in to.options | filter: $select.search',
					                                    label: 'global.literals.section',
					                                    valueProp: 'id',
					                                    labelProp: vm.languageColumn,
					                                    placeholder: '',
					                                    options: ((sections && sections.length > 0)?new SortedArray(sections, vm.languageColumn).sort():[]),
					                                    required: true,
					                                    focus: false
					                                },
					                                hideExpression: function($viewValue, $modelValue, scope) {
					                                	if(sections && sections.length === 1){
															scope.model.section = sections[0];
														}
														return (sections && sections.length === 1);
					                                }
					                            },
												{
					                                key: 'tramClassification',
					                                type: 'annexaSelectRow',
					                                className: 'col-sm-12',
					                                data: {
					                                    informed:true,
					                                    row:true,
					                                    clear: function($event,model,key, $select) {
					                                        $event.stopPropagation();
					                                        model[key] = undefined;
					                                        if($select) {
					                                            $select.selected = undefined;
					                                            $select.search = undefined;
					                                        }
					                                    }
					                                },
					                                templateOptions: {
					                                    optionsAttr: 'bs-options',
					                                    ngOptions: 'option in to.options | filter: $select.search',
					                                    label: 'global.literals.tramClassification',
					                                    valueProp: 'id',
					                                    labelProp: vm.languageColumn,
					                                    placeholder: '',
					                                    options: ((GlobalDataFactory.tramClassifications && GlobalDataFactory.tramClassifications.length > 0)?new SortedArray(GlobalDataFactory.tramClassifications, vm.languageColumn).sort():[]),
					                                    required: requiredTramClassification,
					                                    focus: false, 
														defaultValue: defaultTramClassification
					                                },
					                                hideExpression: function($viewValue, $modelValue, scope) {
														return !showTramClassification;
					                                }
												}
											],
											model:{}
										},
										options:{},
										secNotification: sn
									}
                                    if(sn.relatedProposal && sn.relatedProposal.notificationTransaction && sections && sections.length > 0
                                            && sn.relatedProposal.notificationTransaction.dossier && sn.relatedProposal.notificationTransaction.dossier.section
                                            && $linq(sections).contains(sn.relatedProposal.notificationTransaction.dossier.section, "(x, y) => x.id == y.id")) {
                                        step.annexaFormly.model.section = sn.relatedProposal.notificationTransaction.dossier.section;
                                    }
									steps.push(step);
								});
								if(steps && steps.length > 0){
									var finishCreateNotifications = function(){
										var selfFinishCreateNotifications = this;
										var sendNotifications = {};
										if(this.wizard && this.wizard.steps){
											var notsOk = true;
											_.forEach(this.wizard.steps, function(step){
												if(notsOk && step && step.secNotification && step.secNotification.relatedProposal && step.secNotification.relatedProposal.id && step.secNotification.relatedProposal.notificationTransaction && step.secNotification.relatedProposal.notificationTransaction.dossier && step.secNotification.relatedProposal.notificationTransaction.dossier.id){
													if(!sendNotifications[step.secNotification.relatedProposal.id]){
														sendNotifications[step.secNotification.relatedProposal.id] = [];
													}
													var thsToN = [];
													if(step.annexaFormly && step.annexaFormly.fields){
														var fieldThirds = $linq(step.annexaFormly.fields).firstOrDefault(undefined, "x => x.key == 'thirds'");
														if(fieldThirds) {
															var selectedThirds = $linq(fieldThirds.data.thirds).where("x => x.selected").toArray();
										                    if(selectedThirds && selectedThirds.length > 0) {
																var addressesSelected = [];
																_.forEach(selectedThirds, function(item) {
										                            var selectedAddress = $linq(item.addressesOrig).firstOrDefault(undefined, "x => x.selected");
										                            if(selectedAddress) {
										                                addressesSelected.push({ address: selectedAddress, third: ((item.third)?item.third:{id:item.id}), dossierId:((item.dossier && item.dossier.id)?item.dossier.id:undefined)});
										                            }
										                        });
										                        if(addressesSelected.length == selectedThirds.length) {
																	thsToN = addressesSelected;
										                        }
															}
														}
													}
													if(!thsToN || thsToN.length === 0){
														notsOk = false;
														var fieldThirds = $linq(step.annexaFormly.fields).firstOrDefault(undefined, "x => x.key == 'thirds'");
														if(fieldThirds) {
															var selectedThirds = $linq(fieldThirds.data.thirds).where("x => x.selected").toArray();
										                    if(selectedThirds && selectedThirds.length > 0) {
																selfFinishCreateNotifications.alerts.push({ msg: 'global.errors.channelNoValidSendNotifications' });
															}else{
																selfFinishCreateNotifications.alerts.push({ msg: 'global.errors.noDestinataris' });
															}
														}
													}
													var docsToN = [];
													if(step.annexaFormly && step.annexaFormly.fields){
								                        if(selectedThirds && selectedThirds.length > 0) {
								                            _.forEach(selectedThirds, function(thi){
								                                  var documentsSelected = $linq(thi.documentsToSelect).where("x => x.selected").toArray();
								                                  if(notsOk && (!documentsSelected || documentsSelected.length == 0)) {
								                                	  notsOk = false;
								                                	  selfFinishCreateNotifications.alerts.push({msg: 'global.publishSite.errors.noThirdDocumentSelected'});
								                                  } else {
								                                	  _.forEach(documentsSelected, function(docSelected){
								                                		  docsToN.push({
								                                			  documentId:docSelected.id,
								                                			  thirdId:thi.third.id,
								                                			  dossierTransactionDocumentId:docSelected.dossierTransactionDocumentId
								                                		  });
								                                	  });
								                                  }
								                              });
								                        }
													}
													if(notsOk && (!docsToN || docsToN.length === 0)){
														notsOk = false;
														selfFinishCreateNotifications.alerts.push({ msg: 'global.errors.noDocuments' });
													}
													if(notsOk){
														thsToN = angular.copy(thsToN);
														var docsToNAux = [];
														_.forEach(docsToN,function(dtn){
															docsToNAux.push({
																document:{id:dtn.documentId}, 
																third:{id:dtn.thirdId},
																dossierTransactionDocumentId:dtn.dossierTransactionDocumentId
															});
														});
											            
														_.forEach(thsToN, function(item){
															var status = undefined;
									                        if(item && item.address && item.address.selectedNotificationType && item.address.selectedNotificationType.statuses && item.address.selectedNotificationType.statuses.length > 0){
									                        	status = item.address.selectedNotificationType.statuses[0];
									                        }
									                        var valueAlertAddress = undefined;
									                        if(item.address.selectedNotificationType.selectedAlertAddress && item.address.selectedNotificationType.selectedAlertAddress.id != 0) {
									                        	var alertAddress = item.address.selectedNotificationType.selectedAlertAddress;
									                        	if(alertAddress && alertAddress.addressType && alertAddress.addressType == 'POSTAL' && alertAddress.address) {
									                        		valueAlertAddress = alertAddress.address[($rootScope.app.configuration.show_long_address_language && $rootScope.app.configuration.show_long_address_language.value?'address':'shortAddress')+Language.getActiveColumn()];
									                        	} else if(alertAddress){
									                        		valueAlertAddress = (alertAddress.phoneInternationalPrefix?alertAddress.phoneInternationalPrefix:'') + alertAddress.telematicValue;
									                        	}
									                        }
									                    	delete item.address.selectedNotificationType.selectedAlertAddress;
									                    	delete item.address.selectedNotificationType.alertAddresses;
									                    	var secNotificationSend = angular.copy(step.secNotification);
									                    	if(secNotificationSend && secNotificationSend.relatedProposal && secNotificationSend.relatedProposal.id) {
									                    		secNotificationSend.relatedProposal = {id: secNotificationSend.relatedProposal.id};
									                    	}
															var sendNotification = {
																secNotification:angular.copy(secNotificationSend),
																documents:angular.copy(docsToNAux),
																thirds: [{
																	third: {id:item.third.id},
																	address: ((item.address && item.address.selectedNotificationType && item.address.selectedNotificationType.addressType == 'OTHER')?undefined:{id:item.address.id}), 
	                            									statuses: [((status && status.id)?{createdDate: new Date(), status: {id:status.id}}:undefined)],
	                            									telematicNumber: valueAlertAddress,
																	dossierId: item.dossierId
																}],
																notificationType: ((item.address && item.address.selectedNotificationType)?{id:item.address.selectedNotificationType.id}:undefined),
																section: ((step.annexaFormly && step.annexaFormly.model && step.annexaFormly.model.section && step.annexaFormly.model.section.id)?{id:step.annexaFormly.model.section.id}:undefined),
																tramClassification: ((step.annexaFormly && step.annexaFormly.model && step.annexaFormly.model.tramClassification && step.annexaFormly.model.tramClassification.id)?{id:step.annexaFormly.model.tramClassification.id}:undefined)
															}
															if(sendNotification.secNotification){
																sendNotification.secNotification.allDocuments = undefined;
															}
														  	sendNotifications[step.secNotification.relatedProposal.id].push(JSOG.encode(sendNotification));
														});
													}
												}
											});
											if(notsOk){
												SecFactory.createNotificationsSecNotificationDocuments(-1, sendNotifications).then(function(dataCall, status) {
													if(dataCall){
														SecFactory.showErrors(secNotOk, dataCall, 'global.sec.literals.okNot', 'global.sec.literals.koNot', true);
														selfFinishCreateNotifications.close();
													} else {
														selfFinishCreateNotifications.close();
														$state.reload();
													}
												}).catch(function(error) {
													DialogsFactory.error($filter('translate')('global.sec.literals.errorSecNotfication'));
										        });
											}
										}
									}
									var modalCreateNotifications = angular.copy(globalModals.secNotificationCreateNotifications);
									modalCreateNotifications.wizard.steps = steps;
									modalCreateNotifications.wizard.modal = modalCreateNotifications;
									AnnexaFormlyFactory.showModal('fromScannerWizardModal', modalCreateNotifications, finishCreateNotifications, false, true);
								}
							}
						}
						var propsWithSons = $linq(secNotOk).where("x => x.relatedProposal && x.relatedProposal.workWithDossierSons").select("x => x.relatedProposal.id").toArray();
						if(propsWithSons && propsWithSons.length > 0){
							SecFactory.getDossierThirdsAndSons(propsWithSons, undefined).then(function(dataThirdsAndSons, status) {
								var secNotOkAux = []; 
								_.forEach(secNotOk, function(sn){
									var snAux = angular.copy(sn);
									if(sn.relatedProposal && sn.relatedProposal.id && sn.relatedProposal.notificationTransaction && sn.relatedProposal.notificationTransaction.dossier && dataThirdsAndSons[sn.relatedProposal.id]){
										snAux.relatedProposal.notificationTransaction.dossier.thirds = dataThirdsAndSons[sn.relatedProposal.id];
									}
									secNotOkAux.push(snAux);
								});
								createNotificationsModalFinal(secNotOkAux);
							}).catch(function(error) {
								createNotificationsModalFinal(secNotOk);
					        });		
						}else{
							createNotificationsModalFinal(secNotOk);
						}
					}
				}
				
				SecFactory.getSecNotificationsConfigurationProposal("NOTIFICATION", vm.proposalId).then(function(data, status) {
					var dataAux =data;
					if(dataAux && dataAux.length > 0){
						createNotificationsModal(dataAux)
					}else{
						DialogsFactory.error($filter('translate')('global.sec.literals.errorget'));
					}
				}).catch(function(error) {
					DialogsFactory.error($filter('translate')('global.sec.literals.errorget'));
		        });
			}
			
			vm.completeDossierTransactions = function(){
				var createDossierTransactions = function(secNotOk){
					if(secNotOk != null && secNotOk.length > 0){
						var secNotNotExistUser = $linq(secNotOk).where(function(x){
							if(x.transactions && $linq(x.transactions).firstOrDefault(undefined, "x => !x.existUser")){
								return true;
							}else{
								return false;
							}
						}).toArray();
						if(!secNotNotExistUser || (secNotNotExistUser && secNotNotExistUser.length == 0)){
							var message = $filter('translate')('global.sec.literals.secNotificationTransferClose')+'</br>';
							_.forEach(secNotOk, function(sn, index){
								message += '&nbsp;&nbsp;&nbsp;&nbsp;'+$filter('translate')('global.sec.literals.proposal')+': '+sn.relatedProposal.proposalNumber+'</br>';
							});
							DialogsFactory.confirm('global.sec.literals.secNotificationTransferCloseTitle', message)
	        	            .then(function (data) {
        						SecFactory.createNotificationsSecTransactions(-1, JSOG.encode(secNotOk)).then(function(dataCall, status) {
									if(dataCall){
										SecFactory.showErrors(secNotOk, dataCall, 'global.sec.literals.okTrans', 'global.sec.literals.koTrans', true);
									} else {
										$state.reload();
									}
								}).catch(function(error) {
									DialogsFactory.error($filter('translate')('global.sec.literals.errorSecNotfication'));
								});
				           }).catch(function (data) {
	        		       });
						}else{
							var finishedSelectUsers = function(){
								var selfFinishedSelectUsers = this;
								_.forEach(secNotOk, function (value) {
									if(value.transactions && selfFinishedSelectUsers.annexaFormly && selfFinishedSelectUsers.annexaFormly.model){
										_.forEach(value.transactions, function (value2, index) {
											if(value.relatedProposal && value.relatedProposal.id && !value2.existUser){
												if(selfFinishedSelectUsers.annexaFormly.model && selfFinishedSelectUsers.annexaFormly.model[index+"_User_"+value.relatedProposal.id] && selfFinishedSelectUsers.annexaFormly.model[index+"_User_"+value.relatedProposal.id].id){
													value2.responsibleUser = {id:selfFinishedSelectUsers.annexaFormly.model[index+"_User_"+value.relatedProposal.id].id};
												}
											}
										});
									}
								});
								SecFactory.createNotificationsSecTransactions(-1, JSOG.encode(secNotOk)).then(function(dataCall, status) {
									if(dataCall){
										SecFactory.showErrors(secNotOk, dataCall, 'global.sec.literals.okTrans', 'global.sec.literals.koTrans', true);
										selfFinishedSelectUsers.close();
									} else {
										selfFinishedSelectUsers.close();
										$state.reload();
									}
								}).catch(function(error) {
									DialogsFactory.error($filter('translate')('global.sec.literals.errorSecNotfication'));
								});
				           }
							var modalSelectUsers = angular.copy(globalModals.secNotificationResponsibleUsers);
							modalSelectUsers.annexaFormly.model = {};
							_.forEach(secNotNotExistUser, function (value) {
								if(value.transactions){
									_.forEach(value.transactions, function (value2, index) {
										if(value.relatedProposal && value.relatedProposal.id && !value2.existUser){
				                        	modalSelectUsers.annexaFormly.model[index+"_Profile_"+value.profile.id] = value.profile.id;
											modalSelectUsers.annexaFormly.model[index+"_User_"+value.relatedProposal.id] = undefined;
											var field = {
							                    key: index+"_User_"+value.relatedProposal.id,
							                    type: 'annexaSelectRow',
							                    className: 'col-sm-12',
							                    data: {
							                        informed:true,
							                        row:true,
							                        colClass: '',
							                        labelClass: 'label-strong',
							                        clear: function($event,model,key, $select) {
							                            $event.stopPropagation();
							                            model[key] = undefined;
							                            if($select) {
							                                $select.selected = undefined;
							                                $select.search = undefined;
							                            }
							                        }
							                    },
							                    templateOptions: {
							                        optionsAttr: 'bs-options',
							                        ngOptions: 'option in to.options | filter: $select.search',
							                        label: $filter('translate')('global.literals.responsibleUser')+" "+((value.relatedProposal && value.relatedProposal.proposalNumber)?value.relatedProposal.proposalNumber:'')+" - "+((value2.type && value2.type[vm.languageColumn])?value2.type[vm.languageColumn]:''),
							                        valueProp: 'id',
							                        labelProp: 'completeName',
							                        placeholder: '',
							                        options: [],
							                        required: true,
							                        focus: false
							                    },
							                    controller: ['$scope', '$filter', 'Language', 'GlobalDataFactory', 'SecFactory', function($scope, $filter, Language, GlobalDataFactory, SecFactory) {
				                                     $scope.to.options = [];
													 if($scope.model && $scope.model[index+"_Profile_"+value.profile.id]){
														var profile = $linq(GlobalDataFactory.allProfiles).firstOrDefault(undefined, "x => x.id == "+$scope.model[index+"_Profile_"+value.profile.id]);
														if(profile){
															SecFactory.getUserProfiles(profile.id).then(function(data){
																if(data && data.length > 0){
																	$scope.to.options = data;
																}
															}).catch(function(error){});
														}	
													 }
				                                }]
							                }
											modalSelectUsers.annexaFormly.fields.push(field);
										}
									});
								}
	                        });
							AnnexaFormlyFactory.showModal('fromScannerWizardModal', modalSelectUsers, finishedSelectUsers, false);
						}
					}
				}
				
				SecFactory.getSecNotificationsConfigurationProposal("CLOSE_TRANSFER", vm.proposalId).then(function(data, status) {
					var dataAux =data;
					if(dataAux && dataAux.length > 0){
						createDossierTransactions(dataAux)
					}else{
						DialogsFactory.error($filter('translate')('global.sec.literals.errorget'));
					}
				}).catch(function(error) {
					DialogsFactory.error($filter('translate')('global.sec.literals.errorget'));
		        });
			}
			
			vm.publishesInfo = function(){
				if(vm.publishSites && vm.publishSites.length > 0) {
					var pss = [];
					_.forEach(vm.publishSites, function (value, index) {
						if(value.publishSites && value.publishSites.length > 0) {
							_.forEach(value.publishSites, function (ps, index) {
								ps.openFromPublishDate = false;
								ps.openFromUnpublishDate = false;
								pss.push(ps);
							});
						}
					});
					var newModal = {
	                    title: 'global.literals.infoPublishStatus',
	                    size: 'modal-lg',
	                    fieldsDefinition: [],
	                	alerts: [],
	                	model: {},
	                    submitModal: function() {
	                    }
	                };

					newModal.fieldsDefinition.push({ type: 'field', id: 'publishes', fieldType: 'component', componentType: 'annexa-publish-sites-info', disabled: false,
	                    data: {
	                        publishSites: pss
	                    }    
	                });
					
					var submitModalPublishInfo = function () {
				        var modal = this;
				        
				        var modalPublishes = $linq(modal.fields).firstOrDefault(undefined, "x => x.key == 'publishes'");
				        if(modalPublishes && modalPublishes.data && modalPublishes.data.publishSites) {
					        TramNewFactory.updateProposalPublishSites(modalPublishes.data.publishSites).then(function (data) {
					        	newModal.close();
					        	$state.reload();
	                        }).catch(function (error) {
	                        	DialogsFactory.error($filter('translate')('global.publishSite.errors.updatePublishSiteStatus'));
	                        });
				        }
				    }
					
					newModal.submitModal = submitModalPublishInfo;
					
					AnnexaFormlyFactory.showAnnexaFormModal('modalPublishesInfo', newModal);
				}
			}
			
			$scope.$on('updateNotificationState', function(event, args) {
				if(args && args.id && args.globalStatus){
					_.forEach(vm.content, function(cont){
						if(cont.not && cont.not.id == args.id){
							cont.not.globalStatus = args.globalStatus;
						}
					});
				}
	        });
			
			vm.getDocStatusTitle = function (document) {
                return 'global.documentState.' + document.docStatus;
            }

            vm.getDocStatusIcon = function (document) {
                switch (document.docStatus) {
                    case 'NEW':
                        return 'fa fa-circle-o text-blue fa-lg';
                        break;
                    case 'NEW_AND_ATTACHMENT':
                        return 'fa fa-adjust text-blue fa-lg';
                        break;
                    case 'PENDING_SIGN':
                        return 'fa fa-circle text-blue fa-lg';
                        break;
                    case 'SIGNED':
                        return 'fa fa-circle text-success fa-lg';
                        break;
                    case 'REJECTED':
                        return 'fa fa-circle text-danger fa-lg';
                        break;
                    case 'COMPLETE':
                        return 'fa fa-dot-circle-o text-success fa-lg v-t';
                    case 'ARCHIVED':
                        return 'fa fa-circle-o text-success fa-lg v-t';
                        break;
                }
            }
            vm.documentIsExpired = function(expirationDate){
				var documentIsExpired = false;
				if(expirationDate){
					if(expirationDate instanceof Date){
						if(expirationDate < new Date()){
							documentIsExpired = true;
						}
					}else {
						if(new Date(expirationDate) < new Date()){
							documentIsExpired = true;
						}
					}
				}
				return  documentIsExpired;
			}
            
            vm.viewDocument = function(document){
				if(document) {
                    $rootScope.loading(true);
                    var windowReference = window.open();
                    DccumentsFactory.getDocContent(document.id)
                        .then(function(data) {
                        	$rootScope.loading(false);
                        	windowReference.location = data;
                    }).catch(function (error) {
                        $rootScope.loading(false);
                        windowReference.close();
                    });
                }
			}
			vm.authCopyDocument = function(document){
				 $rootScope.loading(true);
                 var windowReference = window.open();
                 DccumentsFactory.getAuthCopyContent(document.id)
                 .then(function(data) {
                	 $rootScope.loading(false);
                	 windowReference.location = data;
                 }).catch(function (error) {
                	 $rootScope.loading(false);
                     windowReference.close();
                 });
			}
			
			vm.getTitleDocumentView = function(type){
				var titleDocView = "";
				if(type == "authCopy"){
					titleDocView = "[" + $filter('translate')('global.literals.seeAuthCopy') + "]";
				}else{
					titleDocView = "[" + $filter('translate')('global.literals.seeDocument') + "]";
				}
				
				return titleDocView;
			}
			vm.showViewDocument = function(document){
				if(AnnexaDocumentActionsButtonFactory.isDocumentConvertibleToPdf(document, true)){
					return true;
				}
				
				return false;
			}

            this.$onInit = function () {
            	if(vm.content && vm.content.length > 0 && vm.content[0].notSecDoc && vm.content[0].notSecDoc.length > 0) {
                	vm.showSecNotTable = true;
            		vm.secNotifications = vm.content[0].notSecDoc;
            	}
            	if(vm.content && vm.content.length > 0 && vm.content[0].dossierTranDocSec && vm.content[0].dossierTranDocSec.length > 0) {
                	vm.showSecNotDocTable = true;
            		vm.secNotDocuments = vm.content[0].dossierTranDocSec;
            	}
            };
        }],
        bindings: {
            content: '=',
            emptyText: '@',
            isEdit: '=',
            entryType: '@?',
            origin: '@',
            showSessionSecNotifications: '=',
            publishSites: '='
        }
    })